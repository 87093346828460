import React from "react";
import { Organization } from "../type/organization";

type Props = {
  org: Organization;
  orgUser: any;
};

function AgencyInfo({ org, orgUser }: Props) {
  return (
    <div className="flex flex-1 w-[800px] h-[630px] items-start mx-auto">
      <div className="flex flex-1 flex-col  h-full px-10">
        <div className="mb-4">
          <p className="text-sm">업체명</p>
          <p className="orgInfo">{org.name}</p>
        </div>
        <div className="mb-4">
          <p className="text-sm">주소</p>
          <p className="orgInfo">{org.address}</p>
        </div>
        <div className="mb-4">
          <p className="text-sm">상세주소</p>
          <p className="orgInfo">{org.detailAddress}</p>
        </div>

        <div className="flex gap-3 ">
          <div className="mb-4 flex flex-col flex-1 ">
            <p>대표</p>
            <p className="orgInfo">{orgUser.name || "-"}</p>
          </div>
          <div className="mb-4 flex flex-col flex-1 ">
            <p>연락처</p>
            <p className="orgInfo">{org.phoneNumber}</p>
          </div>
        </div>
        <div className="mb-4">
          <p className="text-sm">이메일</p>
          <p className="orgInfo">{org.email || "-"}</p>
        </div>
        <div className="mb-4">
          <p className="text-sm">사업자등록번호</p>
          <p className="orgInfo">{org.licenseNumber}</p>
        </div>
        <div className="mb-4 flex flex-col flex-1 ">
          <p>소속지역</p>
          <p className="orgInfo">{org.affiliation}</p>
        </div>
      </div>
      <div className="flex flex-1 flex-col h-[570px] items-center justify-center border rounded-md ">
        {org.certificate ? (
          <img className="h-full" src={org.certificate} alt="사업자 등록증" />
        ) : (
          <p className="text-sm text-gray-500">
            사업자 등록증 이미지가 없습니다.
          </p>
        )}
      </div>
    </div>
  );
}

export default AgencyInfo;
