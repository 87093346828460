import React, { useState } from "react";
import axios from "../api/axios";

function ChangePw({
  cancleChangePw,
  id,
}: {
  cancleChangePw: () => void;
  id: string | undefined;
}) {
  const [password, setPassword] = useState("");
  const [cpw, setCpw] = useState("");

  const changePw = async () => {
    if (password !== cpw) return alert("비밀번호와 비밀번호 확인이 다릅니다.");
    try {
      await axios.put("admin/organization/password", {
        id,
        password,
      });
      cancleChangePw();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="flex flex-1 justify-between gap-3">
        <div>
          <p>비밀번호</p>
          <input
            className="orgInfo w-full"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <p>비밀번호확인</p>
          <input
            className="orgInfo w-full"
            type="password"
            value={cpw}
            onChange={(e) => setCpw(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-5">
        <button
          onClick={cancleChangePw}
          className=" border-red-400 border mr-2 text-red-400 "
        >
          취소
        </button>
        <button onClick={changePw} className=" bg-red-400 text-white ">
          변경
        </button>
      </div>
    </div>
  );
}

export default ChangePw;
