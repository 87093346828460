import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import SideBarItem from "./SideBarItem";
import {
  faArrowRightFromBracket,
  faHospital,
  faIdCard,
  faUserDoctor,
  faList,
  faTruckMedical,
  faHouse,
  faTableList,
  faHeadset,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../state/store";
import { logoutAsync, selectUser } from "../state/userSlice";

const SideBar = () => {
  const user = useAppSelector(selectUser);
  const pathName = useLocation().pathname;
  const dispatch = useAppDispatch();
  const isLoggedIn = user.id > 0;

  const sideBarItem = [
    { id: 1, name: "홈", icon: faHouse, path: "#" },
    {
      id: 2,
      name: "병원",
      icon: faHospital,
      path: "/organizations/hospital",
    },
    {
      id: 3,
      name: "구급업체",
      icon: faTruckMedical,
      path: "/organizations/agency",
    },
    {
      id: 4,
      name: "드라이버",
      icon: faIdCard,
      path: "/users/driver",
    },
    { id: 5, name: "응급의료인", icon: faUserDoctor, path: "/users/hero" },
    { id: 6, name: "호출이", icon: faUserDoctor, path: "/users/client" },
    { id: 7, name: "이송내역", icon: faTableList, path: "/offer_history" },
    { id: 8, name: "공지사항/이벤트", icon: faList, path: "/notice" },
    // { id: 8, name: "고객센터", icon: faHeadset, path: "#" },
    { id: 9, name: "Down to CSV", icon: faDownload, path: "/event/down" },
    //   { id: 6, name: " 업체정보", icon: faHouse, path: "/agency" }
  ];

  return (
    <section className="relative w-64 pt-20 border-r bg-white ">
      <ul className="">
        {sideBarItem.map((item) => (
          <SideBarItem
            key={item.id}
            item={item}
            isActive={pathName === item.path ? true : false}
          />
        ))}
      </ul>
      {isLoggedIn && (
        <button
          onClick={() => dispatch(logoutAsync())}
          className="absolute bottom-4 right-4 text-lg p-4"
        >
          <FontAwesomeIcon className="mr-2" icon={faArrowRightFromBracket} />
          로그아웃
        </button>
      )}
    </section>
    // <section className="w-64 bg-gray-100 border-r">
    //   <ul className="p-4">
    //     <li className="">
    //       <Link to="/organizations/agency">업체관리</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/organizations/hospital">병원관리</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/users/agency">업체관리자 관리</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/users/driver">드라이버 관리 * 조직</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/users/hero">히어로 관리</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/users/client">클라이언트 관리</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/users/client-public">클라이언트 공용아이디 관리</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/locations/records">위치정보이용사실</Link>
    //     </li>

    //     <li className="">
    //       <Link to="/locations/queries">위치정보접근기록</Link>
    //     </li>
    //     <li className="">
    //       <Link to="/notice">공지사항/이벤트</Link>
    //     </li>
    //   </ul>
    // </section>
  );
};

export default SideBar;
