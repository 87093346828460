import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import QuillComponent from "../components/QuillComponent";
import { Notice } from "../type/notice";

function NoticeForm() {
  const params = useParams();
  const navigate = useNavigate();
  const isUpdate = !!params.id;

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("notice");

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await axios.get<{ notice: Notice }>(
          `/notices/${params.id}`
        );
        const { title, type, content } = res.data.notice;

        setTitle(title);
        setBody(content);
        setType(type);
      } catch (error) {
        console.log(error);
      }
    };

    if (isUpdate) fetchPost();
  }, [isUpdate, params.id]);

  const submit = async () => {
    const axiosBody = {
      title: title,
      content: body,
      type,
    };
    try {
      console.log(axiosBody.content);
      if (!isUpdate) {
        await axios.post("/notices", axiosBody);
      } else {
        await axios.put("/notices", { ...axiosBody, id: params.id });
      }

      navigate(`/notice`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-row flex-1  items-center justify-between">
        <h1 className="text-2xl mb-3">공지사항/이벤트 작성</h1>
      </div>
      <div className="flex flex-row gap-4">
        <select
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="px-4 border rounded-md"
        >
          <option value="notice">공지</option>
          <option value="event">이벤트</option>
        </select>

        <input
          type="text"
          className="w-full px-2 leading-10 border border-gray-300 rounded"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="제목을 입력해 주세요"
        />
      </div>
      <div className="h-[600px] bg-red mt-10 mb-16">
        <QuillComponent body={body} setBody={setBody} />
      </div>
      <div>
        <button
          onClick={submit}
          className="bg-blue-400 px-3 py-1 text-white rounded-md"
        >
          {isUpdate ? "수정" : "저장"}
        </button>
      </div>
    </div>
  );
}

export default NoticeForm;
