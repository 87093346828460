import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import axios from '../api/axios'
import { useAppDispatch, useAppSelector } from '../state/store'
import { selectIsLoggedIn, selectUser, setUser } from '../state/userSlice'

export const delay = (duration: number) =>
  new Promise(resolve => setTimeout(resolve, duration))

const useAuth = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const user = useAppSelector(selectUser)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true)
        const res = await axios.get('/auth/me')
        dispatch(setUser(res.data))
      } catch (error) {
        if (error instanceof AxiosError) {
          console.log(error.response?.data)
        } else console.log({ error })
      } finally {
        setIsLoading(false)
      }
    }

    if (!isLoggedIn) fetchUser()
  }, [dispatch, isLoggedIn])

  return { isLoading, isLoggedIn, user }
}

export default useAuth
