import React, { useCallback, useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../api/axios";

import ImageResize from "quill-image-resize";
Quill.register("modules/ImageResize", ImageResize);

type QuillEditorProps = {
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
};

function QuillComponent({ body, setBody }: QuillEditorProps) {
  const quillRef = useRef<ReactQuill>(null);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.addEventListener("change", async () => {
      if (!input.files) return;
      const file = input.files[0];
      const fileName =
        Date.now() +
        "." +
        file.name.split(".")[file.name.split(".").length - 1];
      try {
        const res = await axios.get<{ url: string }>(
          `/upload/notice-image-url/${fileName}`
        );

        const res2 = await fetch(
          new Request(res.data.url, {
            method: "PUT",
            body: file,
            headers: new Headers({
              "Content-Type": "image/*",
            }),
          })
        );

        if (res2.status !== 200)
          return alert("죄송합니다. 잠시 후 다시 이용해 주세요.");

        const editor = quillRef.current?.getEditor();
        const range = editor?.getSelection();
        if (!range) return;

        editor?.insertEmbed(
          range.index,
          "image",
          `https://goochoori.s3.ap-northeast-2.amazonaws.com/notice-images/${fileName}`
        );
      } catch (error) {
        console.log("quill image update failed: ", error);
      }
    });
    input.click();
    // input.click();

    // input.onchange = async () => {
    //   if (input.files) {
    //     let file: any = input.files[0];
    //     let formData = new FormData();
    //     formData.append("image", file);
    //     let fileName = file.name;

    //     console.log(fileName);
    //   }
    // };
  }, []);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }, "bold", "underline", "image", "link"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ align: [] }, { color: [] }, { background: [] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    };
  }, [imageHandler]);

  const formats = useMemo(
    () => [
      "header",
      "bold",
      "underline",
      "image",
      "link",
      "list",
      "bullet",
      "indent",
      "align",
      "color",
      "background",
    ],
    []
  );

  return (
    <div className="text-editor" style={{ height: "100%" }}>
      <ReactQuill
        style={{ height: "100%" }}
        theme="snow"
        ref={quillRef}
        modules={modules}
        formats={formats}
        value={body}
        onChange={setBody}
      />
    </div>
  );
}

export default QuillComponent;
