import { useParams } from "react-router-dom";
import DriverTable from "../components/DriverTable";
import HeroTable from "../components/HeroTable";
import ClientTable from "../components/ClientTable";
import { useEffect } from "react";

const UsersPage = () => {
  const { role } = useParams();

  let title = "";
  if (role === "driver") title = "드라이버 관리";
  if (role === "hero") title = "히어로 관리";
  if (role === "client-normal") title = "호출이 관리";
  if (role === "client") title = "클라이언트 관리";
  if (role === "client-public") title = "클라이언트 공용아이디 관리";
  if (role === "admin") title = "어드민 관리";

  useEffect(() => {
    console.log("role: ", role);
  }, []);

  return (
    <section className="h-full">
      {role === "driver" && <DriverTable />}
      {role === "hero" && <HeroTable />}
      {role === "client" && <ClientTable />}
    </section>
  );
};

export default UsersPage;
