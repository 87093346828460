import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import axios from "../api/axios";

interface Result {
  id: number;
  role: string;
  name: string;
  email: string;
  phoneNumber: string;
  licenseNumber?: any;
  isp: string;
  ispId: string;
  identifier?: any;
  password?: any;
  profileImage: string;
  coord: Coord;
  location: string;
  pushToken: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  friend: Friend;
  friendUserCall: number;
  newUserCall: number;
}

interface Friend {
  id: number;
  role: string;
  name: string;
  email: string;
  phoneNumber: string;
  licenseNumber?: any;
  isp: string;
  identifier?: any;
  profileImage: string;
  coord: Coord;
  location: string;
  status: string;
  deletedAt?: any;
}

interface Coord {
  latitude: number;
  longitude: number;
}

interface EventData {
  번호: number;
  추천인: string;
  추천인전화번호: string;
  추천인출동횟수: number;
  신규가입자: string;
  신규전화번호: string;
  신규출동횟수: number;
}

export default function DownToCsvPage() {
  const [eventData, setEventData] = useState<EventData[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get<{
          result: Result[];
        }>("/admin/event");

        const data = res.data.result;

        const fin = data.map((d, i) => ({
          번호: i + 1,
          추천인: d.friend.name,
          추천인전화번호: d.friend.phoneNumber,
          추천인출동횟수: d.friendUserCall,
          신규가입자: d.name,
          신규전화번호: d.phoneNumber,
          신규출동횟수: d.newUserCall,
        }));

        setEventData(fin);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <h1 className="font-semibold text-2xl">추천인 이벤트</h1>
      <pre>{JSON.stringify(eventData, null, 2)}</pre>
      <CSVLink
        data={eventData}
        filename={"goochoori-event-list.csv"}
        className="bg-black text-white w-20 text-center"
      >
        다운로드
      </CSVLink>
    </>
  );
}
