import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DaumPostcode from "react-daum-postcode";
import usePhoneNumberFormat from "../hooks/usePhoneNumberFormat";
import axios from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { default as axiosTrue } from "axios";
import useLicenseNumberFormat from "../hooks/useLicenseNumberFormat";
import { Organization } from "../type/organization";
import { User } from "../type/user";

export type Coordinate = {
  longitude: string;
  latitude: string;
};
function EditAgencyForm() {
  const [name, setName] = useState("");
  const [checkName, setCheckName] = useState(false);
  const [address, setAddress] = useState("");
  const [checkAddress, setCheckAddress] = useState(false);
  const [email, setEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [certificate, setCertificate] = useState("");
  const [affiliation, setAffiliation] = useState("");

  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [detailAddress, setDetailAddress] = useState("");

  const { phoneNumber, handlePhoneNumberChanged, setPhoneNumber } =
    usePhoneNumberFormat("");
  const [chcekPhone, setCheckPhone] = useState(true);
  const { licenseNumber, setLicenseNumber } = useLicenseNumberFormat("");

  const navigate = useNavigate();
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const phonuNumberRef = useRef<HTMLInputElement>(null);
  const licenseNumberRef = useRef<HTMLInputElement>(null);

  const editAgency = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log(detailAddress);
    const body = {
      id: params.editId,
      name,
      licenseNumber,
      address,
      detailAddress,
      phoneNumber,
      email,
      certificate,
      affiliation,
      coordinate,
    };
    try {
      await axios.put<{
        success: boolean;
      }>("admin/organizations/agency/update", body);

      navigate(`/organizations/agency/${params.editId}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const res = await axios.get<{ organization: Organization; user: User }>(
          `/admin/organizations/agency/${params.editId}`
        );
        console.log("res.data.organization", res.data);
        setName(res.data.organization.name);
        setAddress(res.data.organization.address);
        setDetailAddress(res.data.organization.detailAddress || "");
        setLicenseNumber(res.data.organization.licenseNumber);
        setPhoneNumber(res.data.organization.phoneNumber);
        setEmail(res.data.organization.email);
        setAffiliation(res.data.organization.affiliation);
        setCertificate(res.data.organization.certificate);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrg();
  }, [params.editId, setLicenseNumber, setPhoneNumber]);

  const getCoordinate = async (roadAddress: string) => {
    try {
      const res = await axiosTrue.get(
        `https://dapi.kakao.com/v2/local/search/address?query=${roadAddress}`,
        {
          headers: {
            Authorization: `KakaoAK bf551c43508f70aa9311b4a6a4fcbccc`,
          },
        }
      );

      const result = res.data.documents[0];

      setCoordinate({ longitude: result.x, latitude: result.y });
      setAddress(roadAddress);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="relative px-4 py-10 bg-background flex-1 flex flex-col ">
      <form
        className="mx-auto flex flex-row w-3/4 h-full gap-10 justify-center"
        onSubmit={editAgency}
      >
        <div className="w-[480px] px-8 bg-white rounded-lg py-10">
          <h1 className="mb-4 text-2xl text-center font-bold">업체정보 수정</h1>
          <div className="flex flex-col  mt-3">
            <label htmlFor="name" className="mb-2">
              업체명
            </label>
            <input
              value={name}
              onChange={(e) => {
                setCheckName(true);
                setName(e.target.value);
              }}
              id="name"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!checkName && (
            <p className="text-sm text-red">업체명을 입력해주세요.</p>
          )}

          <div className="flex flex-col mt-3">
            <label htmlFor="licenseNumber" className="mb-2">
              사업자 등록번호
            </label>
            <input
              ref={licenseNumberRef}
              value={licenseNumber}
              readOnly
              id="licenseNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          <div className="relative flex flex-col mt-3">
            <label htmlFor="address" className="mb-2">
              주소
            </label>
            <input
              autoComplete="off"
              onFocus={(e) => {
                e.target.blur();
                setCheckAddress(true);
                setIsModalOpen(true);
              }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              id="address"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          {isModalOpen && (
            <div className="relative">
              <div className="absolute p-4 bg-[#ECECEC] rounded w-96">
                <div className="flex justify-between mb-3">
                  <div className="">
                    <h3 className="text-xl font-semibold">주소검색</h3>
                  </div>
                  <div className="">
                    <button
                      className="px-2 py-1 text-xs bg-white rounded-lg"
                      onClick={() => setIsModalOpen(false)}
                    >
                      닫기
                    </button>
                  </div>
                </div>
                <DaumPostcode
                  defaultQuery={address}
                  onComplete={(address) => getCoordinate(address.roadAddress)}
                  onClose={() => {
                    setIsModalOpen(false);
                    phonuNumberRef.current?.focus();
                  }}
                  autoClose={true}
                />
              </div>
            </div>
          )}
          {!checkAddress && (
            <p className="text-sm text-red">주소가 올바르지 않습니다.</p>
          )}

          <div className="flex flex-col mt-3">
            <label htmlFor="detailAddress" className="mb-2">
              상세주소
            </label>
            <input
              value={detailAddress}
              onChange={(e) => {
                setDetailAddress(e.target.value);
              }}
              id="phoneNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="phoneNumber" className="mb-2">
              전화번호
            </label>
            <input
              ref={phonuNumberRef}
              value={phoneNumber}
              onChange={(e) => {
                setCheckPhone(true);
                handlePhoneNumberChanged(e);
              }}
              id="phoneNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!chcekPhone && (
            <p className="text-sm text-red">전화번호가 올바르지 않습니다..</p>
          )}

          <div className="flex flex-col mt-3">
            <label htmlFor="email" className="mb-2">
              이메일
            </label>
            <input
              value={email}
              onChange={(e) => {
                setCheckEmail(true);
                setEmail(e.target.value);
              }}
              id="email"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!checkEmail && (
            <p className="text-sm text-red">이메일이 올바르지 않습니다.</p>
          )}

          <div className="flex flex-col mt-3">
            <label htmlFor="affiliation" className="mb-2">
              소속 지역
            </label>
            <select
              value={affiliation}
              onChange={(e) => setAffiliation(e.target.value)}
              id="affiliation"
              className="p-2 mb-2 border rounded-md"
            >
              <option value="강원">강원도</option>
              <option value="경기">경기도</option>
              <option value="경남">경상남도</option>
              <option value="경북">경상북도</option>
              <option value="광주">광주광역시</option>
              <option value="대구">대구광역시</option>
              <option value="부산">부산광역시</option>
              <option value="서울">서울특별시</option>
              <option value="세종">세종특별자치시</option>
              <option value="울산">울산광역시</option>
              <option value="인천">인천광역시</option>
              <option value="전남">전라남도</option>
              <option value="전북">전라북도</option>
              <option value="제주">제주특별자치도</option>
              <option value="충남">충청남도</option>
              <option value="충북">충청북도</option>
            </select>
          </div>

          <div className="flex mt-5 justify-end gap-2">
            <button className="px-4 py-2 text-white bg-blue-400 rounded">
              수정하기
            </button>
          </div>
        </div>

        <div className="w-[480px] bg-white items-center justify-center flex flex-col rounded-lg">
          <label
            htmlFor="certificate"
            className="w-full h-[650px] items-center justify-center flex flex-col cursor-pointer"
          >
            {certificate ? (
              <img
                className="block h-full"
                src={certificate}
                alt="사업자등록증"
              />
            ) : (
              <>
                <span>사업자등록증 추가</span>
                <FontAwesomeIcon
                  className="mt-5 text-4xl"
                  icon={faPlus}
                  color="black"
                />
              </>
            )}
            {!certificate && (
              <p className="texte-sm text-red mt-2">
                사업자 등록증을 추가해주세요.
              </p>
            )}
          </label>
        </div>
      </form>
    </section>
  );
}

export default EditAgencyForm;
