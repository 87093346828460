import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "../api/axios";
import { RootState } from "./store";

export interface User {
  [index: string]: any;
  id: number;
  role: "admin" | "";
  name: string;
  email: string;
  profileImage: string;
}

const initialState: User = {
  id: -1,
  name: "",
  profileImage: "",
  email: "",
  role: "",
};

export const loginAsync = createAsyncThunk(
  "user/loginAsync",
  async (credentials: { id: string; password: string }, _api) => {
    const { id, password } = credentials;
    const response = await axios.post<{ admin: any; accessToken: string }>(
      "/auth/admin-login",
      { id, password },
      { withCredentials: true }
    );
    axios.defaults.headers.common.authorization = `Bearer ${response.data.accessToken}`;
    return response.data.admin;
  }
);

export const logoutAsync = createAsyncThunk("user/logoutAsync", async () => {
  const response = await axios.get("/auth/logout", { withCredentials: true });

  axios.defaults.headers.common.authorization = false;

  return response.status;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<User>>) => {
      Object.keys(state).forEach((key) => {
        if (action.payload.role !== "admin") return;
        if (action.payload[key]) state[key] = action.payload[key];
      });
    },
    resetUser: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAsync.fulfilled, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        Object.keys(state).forEach((key) => {
          if (action.payload[key]) state[key] = action.payload[key];
        });
      }),
});

export const { resetUser, setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectIsLoggedIn = (state: RootState) => state.user.id > -1;

export default userSlice;
