import dayjs from "dayjs";
import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import Pagination from "../components/Pagination";
import { oderStatus } from "../lib/helpers";
import Order, { OrderStatus } from "../type/Order";

function OfferHistory() {
  const [searchVal, setSerchVal] = useState("");
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectPage, setSelectPage] = useState(1);
  const [total, setTotal] = useState(0);
  const params = useParams();
  const navigation = useNavigate();

  const getSearchData = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigation(`/offer_history/${searchVal}`);
    // try {
    //   const res = await axios.get(
    //     `/admin/order/history?page=1&listNum=10&search=${searchVal}`
    //   );
    //   console.log(res.data.orders[0]);
    //   setOrders(res.data.orders[0]);
    //   setTotal(res.data.orders[1]);
    //   setSelectPage(1);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    // if (params.query) return;
    console.log("qddds");
    const getData = async () => {
      const { query } = params;
      console.log(query);
      try {
        const res = await axios.get(
          `/admin/order/history?page=1&listNum=10&search=${query || ""}`
        );
        console.log(res.data.orders);
        setOrders(res.data.orders[0]);
        setTotal(res.data.orders[1]);
        setSelectPage(1);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [params]);

  useEffect(() => {
    console.log("changePageNum");
    const changePageNum = async () => {
      try {
        const { query } = params;
        const res = await axios.get(
          `/admin/order/history?page=${selectPage}&listNum=10&search=${
            query || ""
          }`
        );
        console.log(res.data.orders);
        setOrders(res.data.orders[0]);
        setTotal(res.data.orders[1]);
      } catch (error) {
        console.log(error);
      }
    };
    changePageNum();
  }, [params, selectPage]);

  return (
    <div className="p-4">
      <div className="flex flex-row flex-1  items-center justify-between">
        <h1 className="text-2xl mb-3">전체 이송내역</h1>
      </div>
      <div className="py-5 px-8 flex-row flex rounded-lg justify-end">
        <form onSubmit={getSearchData}>
          <div>
            <input
              className="orgInfo"
              value={searchVal}
              onChange={(e) => setSerchVal(e.target.value)}
            />
            <button className="bg-blue-500 ml-2 text-white">검색</button>
          </div>
        </form>
      </div>
      <table>
        <thead>
          <tr>
            <th>날짜</th>
            <th>구급업체</th>
            <th>드라이버</th>
            <th>구조사</th>
            <th>출발병원/도착병원</th>
            <th>상태</th>
            <th>호출/탑승/완료</th>
            <th>전체/수수료</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{dayjs(order.createdAt).format("YYYY-MM-DD")}</td>
              <td>
                {order.driver ? (
                  <>
                    <p>{order.driver?.organization?.name || "-"}</p>
                    <p>({order.driver?.organization?.phoneNumber})</p>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {order.driver ? (
                  <>
                    <p>{order.driver?.name || "-"}</p>
                    <p>({order.driver?.phoneNumber})</p>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {order.hero ? (
                  <>
                    <p>{order.hero?.name || "-"}</p>
                    <p>({order.hero?.phoneNumber})</p>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td>
                <p>{order.departure.name} /</p>
                <p>{order.destination.name}</p>
              </td>
              <td>{oderStatus[order.status]}</td>
              <td>
                {dayjs(order.createdAt).format("HH:mm")}
                {order.status !== OrderStatus.CANCELLED
                  ? ` / ${dayjs(order.createdAt).format("HH:mm")}`
                  : " / -"}
                {order.status === "completed"
                  ? ` / ${dayjs(order.completedAt).format("HH:mm")}`
                  : " / -"}
              </td>
              <td>
                {order.status === "completed"
                  ? `${order.invoice?.totalFee} / ${order.invoice?.heroFee}`
                  : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {total !== 0 && (
        <Pagination
          listNum={10}
          total={total}
          selectPage={selectPage}
          setSelectPage={setSelectPage}
        />
      )}
    </div>
  );
}

export default OfferHistory;
