import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../state/hooks";
import { selectUser } from "../state/userSlice";

const MainPage = () => {
  const user = useAppSelector(selectUser);
  // const isLoggedIn = user.id > 0
  const isLoggedIn = user.id > 0;
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/organizations/hospital/register");
    } else {
      navigate("login");
    }
  }, [isLoggedIn, navigate]);

  return <div className="p-4"></div>;
};

export default MainPage;
