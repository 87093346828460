import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";

export default function Pagination({
  total,
  listNum,
  currentPage,
  onClickPage,
}: {
  total: number;
  listNum: number;
  currentPage: number;
  onClickPage: (page: number) => void;
}) {
  /** 현재 페이지 그룹 */
  const [currentGroupIdx, setCurrentGroupIdx] = useState(0);
  /** 하나의 페이지그룹 속 페이지 개수 */
  const pageInGroup = useMemo(() => 5, []);
  /** 전체 페이지 수 */
  const totalPage = useMemo(() => Math.ceil(total / listNum), [total, listNum]);
  /** 전체 페이지그룹 수 (ex: 총 31페이지, pageInGroup 10일 때 totalGroup=4) */
  const totalGroup = useMemo(
    () => Math.ceil(totalPage / pageInGroup),
    [totalPage, pageInGroup]
  );
  /** 현재 페이지그룹의 첫페이지 */
  const firstPage = useMemo(
    () => pageInGroup * (currentGroupIdx + 1) - (pageInGroup - 1),
    [pageInGroup, currentGroupIdx]
  );

  /** 현재 페이지그룹의 마지막페이지 */
  const lastPage = useMemo(() => {
    let last = pageInGroup * (currentGroupIdx + 1);
    if (totalPage < last) {
      last = totalPage;
    }

    return last;
  }, [pageInGroup, currentGroupIdx, totalPage]);

  /** 맨앞으로 */
  const handleFirstIdx = () => {
    // console.group("[first]");
    // console.log("total: ", total);
    // console.log("listNum: ", listNum);
    // console.log("currentGroupIdx: ", currentGroupIdx);
    // console.log("pageInGroup: ", pageInGroup);
    // console.log("totalPage: ", totalPage);
    // console.log("totalGroup: ", totalGroup);
    // console.log("firstPage: ", firstPage);
    // console.log("lastPage: ", lastPage);
    // console.groupEnd();

    if (currentPage === 1) return;
    setCurrentGroupIdx(0);
    onClickPage(1);
  };

  /** 맨뒤로 */
  const handleLastIdx = () => {
    if (currentPage === totalPage) return;
    setCurrentGroupIdx(totalGroup - 1);
    onClickPage(totalPage);
  };

  /** 이전페이지로 */
  const handleLeftIdx = () => {
    // console.group("[left]");
    // console.log("currrentPage: ", currentPage);
    // console.log("totalpage: ", firstPage);
    // console.log("currentGroupIdx: ", currentGroupIdx);
    // console.groupEnd();

    if (currentPage === 1) return;
    if (currentPage === firstPage) {
      setCurrentGroupIdx(currentGroupIdx - 1);
    }

    onClickPage(currentPage - 1);
  };

  /** 다음페이지로 */
  const handleRightIdx = () => {
    console.group("[right]");
    console.log("currrentPage: ", currentPage);
    console.log("totalpage: ", totalPage);
    console.log("currentGroupIdx: ", currentGroupIdx);
    console.log("totalGroup", totalGroup);
    console.groupEnd();

    if (currentPage === totalPage) return;
    if (
      currentPage === lastPage &&
      currentPage < totalPage &&
      currentGroupIdx < totalGroup
    ) {
      setCurrentGroupIdx(currentGroupIdx + 1);
    }

    onClickPage(currentPage + 1);
  };

  const handleClick = (idx: number) => {
    onClickPage(idx + 1 + currentGroupIdx * pageInGroup);
  };

  return (
    <div className="flex items-center justify-center gap-3 mt-6 mb-6 select-none">
      <FontAwesomeIcon
        icon={faBackward}
        onClick={handleFirstIdx}
        style={{ cursor: "pointer" }}
      />
      <FontAwesomeIcon
        icon={faArrowLeft}
        onClick={handleLeftIdx}
        style={{ cursor: "pointer" }}
      />
      {Array(
        lastPage % pageInGroup === 0 ? pageInGroup : lastPage % pageInGroup
      )
        .fill(0)
        .map((_, idx) => {
          return (
            <div
              className={`${
                idx + 1 + currentGroupIdx * pageInGroup === currentPage
                  ? "text-white bg-[#1471f7]"
                  : "text-black bg-[#efefef]"
              } w-8 h-8 text-center leading-8 font-bold rounded cursor-pointer`}
              key={idx + 1}
              onClick={() => handleClick(idx)}
            >
              {idx + 1 + currentGroupIdx * pageInGroup}
            </div>
          );
        })}
      <FontAwesomeIcon
        icon={faArrowRight}
        onClick={handleRightIdx}
        style={{ cursor: "pointer" }}
      />
      <FontAwesomeIcon
        icon={faForward}
        onClick={handleLastIdx}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}
