import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../api/axios";
import AgencyInfo from "../components/AgencyInfo";
import HospitalInfo from "../components/HospitalInfo";
import { User } from "../state/userSlice";
import { Organization } from "../type/organization";

const OrganizationPage = () => {
  const { id, type } = useParams();
  const [org, setOrg] = useState<any>();
  const [invis, setInvis] = useState<any[]>([]);
  const [agency, setAgency] = useState<Organization[]>([]);
  const [partnerId, setPartnerId] = useState(0);
  const [orgUser, setOrgUser] = useState<any>("");
  // const buttonTitle = org?.isVerified ? "수정" : "승인";
  const isAgency = type === "agency";

  // useEffect(() => console.log(invis), [invis]);

  useEffect(() => {
    const fetchInvitations = async () => {
      const res = await axios.get(
        `/admin/organizations/${type}/${id}/invitations`
      );

      setInvis(res.data);
    };
    if (type === "agency") fetchInvitations();
  }, [id, type]);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const res = await axios.get<{
          organization: Organization;
          user: User;
          partnerAgencyId: number;
        }>(`/admin/organizations/${type}/${id}`);
        console.log("res.data.organization", res.data);
        setOrg(res.data.organization);
        setOrgUser(res.data.user);

        if (type === "agency") return;

        const res2 = await axios.get<Organization[]>(
          "/admin/organizations/agency"
        );

        const result = res2.data.filter(
          (a) =>
            a.isVerified && a.affiliation === res.data.organization.affiliation
        );

        // console.log("result: ", JSON.stringify(result, null, 2));

        setPartnerId(res.data.partnerAgencyId);
        setAgency(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrg();
  }, [id, type]);

  const changeVerifiyState = useCallback(async () => {
    const res = await axios.put(`/admin/organizations/${type}/update`, {
      id: org?.id,
      isVerified: !org?.isVerified,
    });

    if (res.data.success) setOrg(res.data.organization);
  }, [org, type]);

  const onChangePartner = useCallback(
    async (agencyId: number) => {
      try {
        await axios.put("/admin/partner", {
          partnerId: agencyId,
          hospitalId: Number(id),
        });

        setPartnerId(agencyId);
      } catch (error) {
        console.log(error);
      }
    },
    [id]
  );

  const title = type === "agency" ? "업체 정보" : "병원 정보";

  return (
    <section className="h-full">
      <div className="p-4  flex flex-col w-full">
        <h1 className="text-2xl text-center">{title}</h1>
        <div className="flex justify-end gap-2 mt-10">
          {/* <Link to={`/organizations/${type}`}>목록으로</Link> */}
        </div>
        {org && (
          <div>
            {!isAgency && (
              <HospitalInfo
                orgUser={orgUser}
                partnerId={partnerId}
                onChangePartner={onChangePartner}
                org={org}
                agency={agency}
                id={id}
              />
            )}
          </div>
        )}
        {isAgency && org && (
          <div className="">
            <AgencyInfo org={org} orgUser={orgUser} />
          </div>
        )}

        <div className="flex flex-1 justify-center gap-4">
          <Link
            className="bg-red-400 btn rounded-md text-white"
            to={`/organizations/${type}`}
          >
            목록보기
          </Link>
          {!org?.isVerified && (
            <button
              onClick={() => changeVerifiyState()}
              className="bg-sideBar btn rounded-md text-white "
            >
              승인
            </button>
          )}
          {org?.isVerified && (
            <Link
              className="bg-sideBar btn rounded-md text-white "
              to={`/organizations/${type}/${id}/edit`}
            >
              수정
            </Link>
          )}
          {/* <button
            onClick={() => changeVerifiyState()}
            className="bg-sideBar px-4 py-2 rounded-md text-white "
          >
            {buttonTitle}
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default OrganizationPage;
