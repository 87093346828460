import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";
import usePhoneNumberFormat from "../hooks/usePhoneNumberFormat";
import axios from "../api/axios";
import { default as axiosTrue } from "axios";
import { Coordinate, Organization } from "../type/organization";
import { User } from "../state/userSlice";
import { faL } from "@fortawesome/free-solid-svg-icons";

const HospitalFormPage = () => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPw, setConfirmPw] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [affiliation, setAffiliation] = useState("서울");
  const { phoneNumber, handlePhoneNumberChanged, setPhoneNumber } =
    usePhoneNumberFormat();
  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [manager, setManager] = useState("");
  const { type, editId } = useParams();

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const phonuNumberRef = useRef<HTMLInputElement>(null);

  const getHospital = useCallback(async () => {
    try {
      const res = await axios.get<{ organization: Organization; user: User }>(
        `/admin/organizations/hospital/${editId}`
      );
      const {
        name,
        affiliation,
        email,
        licenseNumber,
        address,
        coordinate,
        phoneNumber,
        manager,
      } = res.data.organization;

      console.log(coordinate);
      setAddress(address);
      setName(name);
      setAffiliation(affiliation);
      setEmail(email);
      setLicenseNumber(licenseNumber);
      setPhoneNumber(phoneNumber);
      setManager(manager);
      setId(res.data.user.identifier);
    } catch (error) {}
  }, [editId, setPhoneNumber]);

  useEffect(() => {
    if (!editId) return;
    getHospital();
  }, [editId, getHospital]);

  const register = useCallback(async () => {
    if (
      !name ||
      !password ||
      !confirmPw ||
      !manager ||
      !phoneNumber ||
      !address ||
      !affiliation
    )
      return alert("필수 정보를 모두 입력해 주세요.");

    if (password !== confirmPw) return alert("비밀번호를 확인해주세요.");

    console.log(coordinate);

    const res = await axios.post(`/admin/organizations/${type}/register`, {
      id,
      password,
      name,
      licenseNumber,
      address,
      email,
      phoneNumber,
      coordinate,
      affiliation,
      manager,
    });

    console.log(res.data);

    navigate(`/organizations/${type}`);
  }, [
    address,
    affiliation,
    confirmPw,
    coordinate,
    email,
    id,
    licenseNumber,
    manager,
    name,
    navigate,
    password,
    phoneNumber,
    type,
  ]);

  const editHospital = async () => {
    if (!name || !manager || !phoneNumber || !address || !affiliation)
      return alert("필수 정보를 모두 입력해 주세요.");
    try {
      const res = await axios.put(`/admin/organizations/hospital/update`, {
        id: editId,
        name,
        licenseNumber,
        address,
        email,
        phoneNumber,
        coordinate,
        affiliation,
        manager,
        type,
      });

      console.log(res.data);

      navigate(`/organizations/hospital`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (editId) {
      editHospital();
    } else {
      register();
    }
  };

  const getCoordinate = async (roadAddress: string) => {
    if (type !== "hospital") return setAddress(roadAddress);
    try {
      const res = await axiosTrue.get(
        `https://dapi.kakao.com/v2/local/search/address?query=${roadAddress}`,
        {
          headers: {
            Authorization: `KakaoAK bf551c43508f70aa9311b4a6a4fcbccc`,
          },
        }
      );

      const result = res.data.documents[0];

      setCoordinate({ longitude: result.x, latitude: result.y });
      setAddress(roadAddress);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="relative h-full p-4">
      <h1 className="mb-4 text-2xl text-center">
        {editId ? "병원수정" : "병원등록"}
      </h1>

      <form
        className="w-[800px] mx-auto flex flex-1 flex-col"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-1 justify-center gap-10 ">
          <div className="flex flex-1 flex-col ">
            <div className="flex flex-col">
              <label htmlFor="name" className="">
                병원명<span className="text-orange-600">*</span>
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                autoComplete="true"
                type="text"
                className="px-3 py-2 mb-5 mt-1 border rounded-md"
              />
            </div>

            <label htmlFor="id" className="">
              아이디<span className="text-orange-600">*</span>
            </label>
            <input
              value={id}
              onChange={(e) => setId(e.target.value)}
              id="id"
              autoComplete="true"
              type="text"
              disabled={editId ? true : false}
              className="px-3 py-2 mb-5 mt-1 border rounded-md"
            />

            {!editId && (
              <div className="flex flex-1 justify-between gap-5">
                <div className="flex flex-1 flex-col">
                  <label htmlFor="password" className="">
                    비밀번호<span className="text-orange-600">*</span>
                  </label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    autoComplete="new-password"
                    type="password"
                    className="px-3 py-2 mb-5 mt-1 border w-full rounded-md"
                  />
                </div>
                <div className="flex flex-1  flex-col">
                  <label htmlFor="password" className="">
                    비밀번호확인<span className="text-orange-600">*</span>
                  </label>
                  <input
                    value={confirmPw}
                    onChange={(e) => setConfirmPw(e.target.value)}
                    id="con_password"
                    type="con_password"
                    autoComplete="new-password"
                    className="px-3 py-2 mb-5 mt-1 border w-full rounded-md"
                  />
                </div>
              </div>
            )}

            <div className="relative flex flex-col">
              <label htmlFor="address" className="">
                주소<span className="text-orange-600">*</span>
              </label>
              <input
                autoComplete="off"
                onFocus={(e) => {
                  e.target.blur();
                  setIsModalOpen(true);
                }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                id="address"
                type="text"
                className="px-3 py-2 mb-5 mt-1 border rounded-md"
              />
            </div>

            {isModalOpen && (
              <div className="relative">
                <div className="absolute p-4 bg-[#ECECEC] rounded w-96">
                  <div className="flex justify-between mb-3">
                    <div className="">
                      <h3 className="text-xl font-semibold">주소검색</h3>
                    </div>
                    <div className="">
                      <button
                        className="px-2 py-1 text-xs bg-white rounded-lg"
                        onClick={() => setIsModalOpen(false)}
                      >
                        닫기
                      </button>
                    </div>
                  </div>
                  <DaumPostcode
                    defaultQuery={address}
                    onComplete={(address) => getCoordinate(address.roadAddress)}
                    onClose={() => {
                      setIsModalOpen(false);
                    }}
                    autoClose={true}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col">
            <div className="flex flex-col">
              <label htmlFor="affiliation" className="">
                소속 지역<span className="text-orange-600">*</span>
              </label>
              <select
                value={affiliation}
                onChange={(e) => setAffiliation(e.target.value)}
                id="affiliation"
                className="px-3 py-2 mb-5 mt-1 border rounded-md"
              >
                <option value="강원">강원도</option>
                <option value="경기">경기도</option>
                <option value="경남">경상남도</option>
                <option value="경북">경상북도</option>
                <option value="광주">광주광역시</option>
                <option value="대구">대구광역시</option>
                <option value="부산">부산광역시</option>
                <option value="서울">서울특별시</option>
                <option value="세종">세종특별자치시</option>
                <option value="울산">울산광역시</option>
                <option value="인천">인천광역시</option>
                <option value="전남">전라남도</option>
                <option value="전북">전라북도</option>
                <option value="제주">제주특별자치도</option>
                <option value="충남">충청남도</option>
                <option value="충북">충청북도</option>
              </select>
            </div>

            <div className="flex justify-between gap-5">
              <div className="flex flex-col">
                <label htmlFor="manager" className="">
                  담당자/직책<span className="text-orange-600">*</span>
                </label>
                <input
                  value={manager || ""}
                  onChange={(e) => setManager(e.target.value)}
                  id="manager"
                  type="text"
                  className="px-3 py-2 mb-5 mt-1 border w-full rounded-md"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="phoneNumber" className="">
                  담당자 연락처<span className="text-orange-600">*</span>
                </label>
                <input
                  ref={phonuNumberRef}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChanged}
                  id="phoneNumber"
                  type="text"
                  className="px-3 py-2 mb-5 mt-1 border w-full rounded-md"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label htmlFor="email" className="">
                이메일
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                type="text"
                className="px-3 py-2 mb-5 mt-1 border rounded-md"
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="licenseNumber" className="">
                사업자 등록번호
              </label>
              <input
                value={licenseNumber}
                onChange={(e) => setLicenseNumber(e.target.value)}
                id="licenseNumber"
                type="text"
                className="px-3 py-2 mb-5 mt-1 border rounded-md"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-4 mt-6">
          <button
            type="button"
            className="px-5 py-2 text-white bg-red-400 rounded"
            onClick={() => navigate(-1)}
          >
            취소
          </button>

          <button className="px-5 py-2 text-white bg-blue-400 rounded">
            {editId ? "수정" : "등록"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default HospitalFormPage;
