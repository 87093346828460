import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import axios from "../api/axios";
import { Organization } from "../type/organization";
import ChangePw from "./ChangePw";

type Props = {
  org: Organization;
  agency: Organization[];
  id: string | undefined;
  partnerId: number;
  onChangePartner: (id: number) => void;
  orgUser: any;
};

function HospitalInfo({
  org,
  agency,
  id,
  partnerId,
  onChangePartner,
  orgUser,
}: Props) {
  const [isChangePw, setIsChangePw] = useState(false);

  const handlePartner = async (e: ChangeEvent<HTMLSelectElement>) => {
    const targetId = Number(e.target.value);
    const partnerName = agency.find((a) => a.id === targetId)?.name;
    const isChoose = window.confirm(
      targetId === 0
        ? "협력사를 삭제하시겠습니까?"
        : `${partnerName}(을)를 협력사로 등록하시겠습니까?`
    );

    if (!isChoose) return;

    onChangePartner(targetId);
  };

  const cancleChangePw = () => {
    setIsChangePw(false);
  };
  return (
    <div className="flex flex-1 w-[800px] h-[450px] items-start mx-auto">
      <div className="flex flex-1 flex-col  h-full px-10">
        <div className="mb-4">
          <p className="text-sm">병원명</p>
          <p className="orgInfo">{org.name}</p>
        </div>
        <div className="mb-4">
          <p className="text-sm">주소</p>
          <p className="orgInfo">{org.address}</p>
        </div>
        <div className="mb-4">
          <p className="text-sm">아이디</p>
          <p className="orgInfo">{orgUser.identifier}</p>
        </div>
        {isChangePw ? (
          <ChangePw id={id} cancleChangePw={cancleChangePw} />
        ) : (
          <div className="mb-4 text-right">
            <button
              onClick={() => setIsChangePw(true)}
              className="bg-sideBar py-2 px-3 text-sm text-white"
            >
              비밀번호 변경
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex gap-3 ">
          <div className="mb-4 flex flex-col flex-1 ">
            <p>소속지역</p>
            <p className="orgInfo">{org.affiliation}</p>
          </div>
          <div className="mb-4 flex flex-col flex-1 ">
            <p>협력업체</p>
            <select
              className="border w-full py-2 rounded-md mt-2"
              value={partnerId}
              onChange={(e) => handlePartner(e)}
            >
              <option value={0}>협력사 없음</option>
              {agency.map((a) => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex gap-3 ">
          <div className="mb-4 flex flex-col flex-1 ">
            <p>담당자/직책</p>
            <p className="orgInfo">{org.manager || "-"}</p>
          </div>
          <div className="mb-4 flex flex-col flex-1 ">
            <p>연락처</p>
            <p className="orgInfo">{org.phoneNumber}</p>
          </div>
        </div>
        <div className="mb-4 flex flex-col flex-1 ">
          <p>이매일</p>
          <p className="orgInfo">{org.email || "-"}</p>
        </div>
        <div className="mb-4 flex flex-col flex-1 ">
          <p>사업자등록번호</p>
          <p className="orgInfo">{org.licenseNumber || "-"}</p>
        </div>
      </div>
    </div>
  );
}

export default HospitalInfo;
