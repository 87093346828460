import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../api/axios'

const UserPage = () => {
  const { role, id } = useParams()
  const [user, setUser] = useState<any>()
  const isHero = role === 'hero'
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUser = async () => {
      const res = await axios.get(`/admin/users/${role}/${id}`)
      setUser(res.data)
      console.log(res.data)
    }
    fetchUser()
  }, [id, role])

  const evaluateCert = useCallback(
    async (result: string) => {
      try {
        const res = await axios.post('/certificate/evaluate', {
          heroId: user?.id,
          certStatus: result
        })
        console.log({ res: res.data })
        navigate(-1)
      } catch (error) {}
    },
    [navigate, user?.id]
  )

  return (
    <section className="h-full">
      <div className="p-4">
        <h1 className="text-2xl text-center">유저 정보</h1>

        {isHero && (
          <div className="flex justify-end gap-2">
            <button onClick={() => evaluateCert('accepted')}>승인</button>
            <button onClick={() => evaluateCert('rejected')}>거절</button>
          </div>
        )}

        {user &&
          Object.keys(user).map(key => (
            <li key={key}>
              {key}{' '}
              <span className="text-xs text-gray-500">
                ({typeof user[key]})
              </span>
              : {JSON.stringify(user[key], null, 2)}
            </li>
          ))}
      </div>
    </section>
  )
}

export default UserPage
