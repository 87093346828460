import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import useAuth from "./hooks/useAuth";
import useAxios from "./hooks/useAxios";
import useDayjs from "./hooks/useDayjs";
import OrganizationPage from "./pages/OrganizationPage";
import HospitalFormPage from "./pages/HospitalFormPage";
import OrganizationsPage from "./pages/OrganizationsPage";
import Layout from "./pages/Layout";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import NotFoundPage from "./pages/NotFoundPage";
import UsersPage from "./pages/UsersPage";
import UserPage from "./pages/UserPage";
import LocationRecordsPage from "./pages/LocationRecordsPage";
import LocationQueriesPage from "./pages/LocationQueriesPage";
import NoticePage from "./pages/NoticePage";
import NoticeForm from "./pages/NoticeForm";
import NoticeDetail from "./pages/NoticeDetail";
import DownToCsvPage from "./pages/DownToCsvPage";
import HeroInfoPage from "./pages/HeroInfoPage";
import OfferHistory from "./pages/OfferHistory";
import EditAgencyForm from "./pages/EditAgencyForm";

function App() {
  useAxios();
  useDayjs();
  useAuth();

  return (
    <Routes>
      <Route path="/app_notice/:postId" element={<NoticeDetail />} />
      <Route element={<Layout />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route element={<RequireAuth />}>
          <Route path="/organizations/:type" element={<OrganizationsPage />} />
          <Route
            path="/organizations/:type/:id"
            element={<OrganizationPage />}
          />

          <Route
            path="/organizations/:type/register"
            element={<HospitalFormPage />}
          />
          <Route
            path="/organizations/hospital/:editId/edit"
            element={<HospitalFormPage />}
          />
          <Route
            path="/organizations/agency/:editId/edit"
            element={<EditAgencyForm />}
          />

          <Route path="/users/:role" element={<UsersPage />} />
          <Route path="/users/hero/:id" element={<HeroInfoPage />} />
          <Route path="/users/:role/:id" element={<UserPage />} />

          <Route path="/locations/records" element={<LocationRecordsPage />} />
          <Route path="/locations/queries" element={<LocationQueriesPage />} />

          <Route path="/notice" element={<NoticePage />} />
          <Route path="/notice/:postId" element={<NoticeDetail />} />
          <Route path="/notice/add" element={<NoticeForm />} />
          <Route path="/notice/edit/:id" element={<NoticeForm />} />
          <Route path="/event/down" element={<DownToCsvPage />} />
          <Route path="/offer_history/" element={<OfferHistory />} />
          <Route path="/offer_history/:query" element={<OfferHistory />} />
        </Route>
      </Route>
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
