import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { loginAsync, selectUser } from "../state/userSlice";

const LoginPage = () => {
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const isLoggedIn = user.id > 0;

  useEffect(() => {
    if (isLoggedIn) navigate(-1);
  }, [isLoggedIn, navigate]);

  const login = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(loginAsync({ id, password: pw }));

      navigate("/");
    },
    [dispatch, id, navigate, pw]
  );

  return (
    <section className="h-full">
      <div className="flex items-center justify-center mt-4">
        <div className="p-4 bg-gray-100 rounded w-96">
          <h1 className="mb-2 text-2xl text-center">로그인</h1>
          <div className="">
            <form className="flex flex-col" onSubmit={login}>
              <input
                type="text"
                placeholder="아이디"
                className="p-2 mb-2 border rounded"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
              <input
                autoComplete="off"
                type="password"
                placeholder="비밀번호"
                className="p-2 mb-4 border rounded"
                value={pw}
                onChange={(e) => setPw(e.target.value)}
              />
              <button className="py-2 text-lg text-white bg-red-400 rounded">
                로그인
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
