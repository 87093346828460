/** user.status */
export enum EUserStatus {
  REST = "rest",
  READY = "ready",
  WORKING = "working",
}
export const userStatusLabel = {
  [EUserStatus.REST]: "휴식",
  [EUserStatus.READY]: "대기",
  [EUserStatus.WORKING]: "이송중",
};

/** certification.status */
export enum ECertificationStatus {
  VERIFYING = "verifying",
  REJECTED = "rejected",
  ACCEPTED = "accepted",
}
export const certificationStatusLabel = {
  [ECertificationStatus.VERIFYING]: "대기",
  [ECertificationStatus.REJECTED]: "거절",
  [ECertificationStatus.ACCEPTED]: "완료",
};
