import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import { Organization } from "../type/organization";

const OrganizationsPage = () => {
  const { type } = useParams();
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const [searchWord, setSearchWord] = useState("");
  const [category, setCategory] = useState("name");
  const navigate = useNavigate();
  let title = "";
  if (type === "agency") title = "업체관리";
  if (type === "hospital") title = "병원관리";

  const handleSearch = async () => {
    try {
      const res = await axios.get<{ organizations: Organization[] }>(
        `/organization/${type}?word=${searchWord}&category=${category}`
      );

      console.log("res: ", JSON.stringify(res.data.organizations, null, 2));
      setOrgs(res.data.organizations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchOrgs = async () => {
      const res = await axios.get(`/admin/organizations/${type}`);
      if (res.data) {
        setOrgs(res.data);
      }
    };

    fetchOrgs();
  }, [type]);

  return (
    <section className="h-full bg-white">
      <div className="p-4">
        <div className="flex-1 flex justify-between">
          <div className="flex items-center gap-10">
            <h1 className="text-2xl">{title}</h1>

            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {type === "hospital" && <option value="name">병원명</option>}

              {type === "agency" && (
                <>
                  <option value="name">업체명</option>
                  <option value="affiliation">지역</option>
                </>
              )}
            </select>

            <div className="flex items-center">
              <input
                type="text"
                className="border px-2 py-1"
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
                onKeyDown={(e) => (e.code === "Enter" ? handleSearch() : null)}
              />
              <div
                className="bg-gray-500 border px-2 py-1 text-white cursor-pointer"
                onClick={handleSearch}
              >
                검색
              </div>
            </div>
          </div>

          {type === "hospital" && (
            <div className="flex justify-end mb-3">
              <Link
                className="bg-sideBar-300 text-white py-1 px-4 rounded-md"
                to={`/organizations/${type}/register`}
              >
                신규등록
              </Link>
            </div>
          )}
        </div>

        <table className="w-full table-auto mt-5">
          <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
            <tr>
              <th className=" ">No.</th>
              <th>이름</th>
              <th>담당자</th>
              <th>전화번호</th>
              <th>주소</th>
              {type === "hospital" && <th>협력사</th>}
              <th>승인 </th>
            </tr>
          </thead>
          <tbody>
            {orgs.length === 0 && (
              <tr>
                <td colSpan={7}>검색 결과가 존재하지 않습니다</td>
              </tr>
            )}
            {orgs.map((hospital: any) => (
              <tr
                key={hospital.id}
                onClick={(e) =>
                  navigate(`/organizations/${type}/${hospital.id}`)
                }
              >
                <td>{hospital.id}</td>
                <td>{hospital.name}</td>
                <td>-</td>
                <td>{hospital.phoneNumber}</td>
                <td>{hospital.address}</td>

                {type === "hospital" && (
                  <td>{hospital.partnerAgencyName ?? "-"}</td>
                )}
                <td className={`${!hospital.isVerified && "text-green"}`}>
                  {hospital.isVerified ? "완료" : "대기"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default OrganizationsPage;
