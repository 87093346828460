import React, { useEffect, useState } from 'react'
import axios from '../api/axios'

type Query = {
  id: number
  createdAt: string
  user: { id: number; name: string }
  ip: string
}

function LocationQueriesPage() {
  const [queries, setQueries] = useState<Query[]>([])

  useEffect(() => {
    const fetchQueries = async () => {
      const res = await axios.get<Query[]>('/admin/locations/queries')
      setQueries(res.data)
    }

    fetchQueries()
  }, [])
  return (
    <div className="p-4">
      <table>
        <thead>
          <tr className="text-left">
            <th className="px-2">id</th>
            <th className="px-2">userId</th>
            <th className="px-2">name</th>
            <th className="px-2">queriedAt</th>
            <th className="px-2">ip</th>
          </tr>
        </thead>
        <tbody>
          {queries.map(q => (
            <tr>
              <td className="px-2">{q.id}</td>
              <td className="px-2">{q.user.id}</td>
              <td className="px-2">{q.user.name}</td>
              <td className="px-2">{q.createdAt}</td>
              {/* <td className="px-2">182.221.39.115(fake data)</td> */}
              <td className="px-2">{q.ip}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LocationQueriesPage
