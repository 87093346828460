import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import { setUser } from "../state/userSlice";
import { User } from "../type/user";

function HeroInfoPage() {
  const { id } = useParams();
  const [user, setUser] = useState<User>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const res = await axios.get(`/admin/users/hero/${id}`);
      setUser(res.data);
      console.log(res.data);
    };
    fetchUser();
  }, [id]);

  const evaluateCert = useCallback(
    async (result: string) => {
      try {
        const res = await axios.post("/certificate/evaluate", {
          heroId: user?.id,
          certStatus: result,
        });
        console.log({ res: res.data });
        navigate(-1);
      } catch (error) {}
    },
    [navigate, user?.id]
  );

  return (
    <div>
      <div className="flex flex-1 w-[800px] h-[550px] items-start mt-12 mx-auto">
        <div className="flex flex-1 flex-col  h-full px-10">
          <div className="mb-10">
            <img
              className="w-44 h-44 rounded-full mx-auto"
              src={user?.profileImage}
              alt="프로필 이미지"
            />
          </div>
          <div className="mb-4">
            <p className="text-sm">이름</p>
            <p className="orgInfo">{user?.name}</p>
          </div>
          <div className="mb-4">
            <p className="text-sm">전화번호</p>
            <p className="orgInfo">{user?.phoneNumber}</p>
          </div>

          <div className="mb-4">
            <p className="text-sm">자걱증번호</p>
            <p className="orgInfo">{user?.certification.licenseNumber}</p>
          </div>
        </div>
        <div className="flex flex-1 flex-col h-[500px] items-center justify-center border rounded-md ">
          {user?.certification ? (
            <img
              className="h-full"
              src={user.certification.imageUrl}
              alt="사업자 등록증"
            />
          ) : (
            <p className="text-sm text-gray-500">
              사업자 등록증 이미지가 없습니다.
            </p>
          )}
        </div>
      </div>
      {user?.certification.status === "verifying" ? (
        <div className="mt-10 flex justify-center gap-5">
          <button
            className="border border-blue-400 text-blue-400"
            onClick={() => evaluateCert("rejected")}
          >
            거절
          </button>
          <button
            className="bg-blue-400 text-white"
            onClick={() => evaluateCert("accepted")}
          >
            승인
          </button>
        </div>
      ) : (
        <div className="mt-10 flex justify-center gap-5">
          <Link to={"/users/hero"} className="btn bg-blue-400 text-white">
            목록보기
          </Link>
        </div>
      )}
    </div>
  );
}

export default HeroInfoPage;
