import { selectUser } from "../state/userSlice";
import { useAppSelector } from "../state/store";
import { Link } from "react-router-dom";
// import { useAppDispatch } from '../state/store'

import logo from "../asset/goochoori-logo.png";

const MenuBar = () => {
  const user = useAppSelector(selectUser);
  const isLoggedIn = user.id > 0;
  return (
    <div className="sticky top-0 flex items-center justify-between h-20 px-6  py-2 bg-white border-b z-[31]">
      <Link to="/" className="">
        <img className="w-40" src={logo} alt="logo" />
        {/* <h1 className="text-2xl">스마일서비스</h1> */}
      </Link>

      {!isLoggedIn ? (
        <div className="">
          <Link
            to="/login"
            className="text-white bg-gray-300 py-2 px-4 rounded-lg"
          >
            로그인
          </Link>
        </div>
      ) : (
        <p className="text-xl">관리자</p>
      )}
    </div>
    // <div className="sticky top-0 flex items-center justify-between h-16 px-4 py-2 bg-white border-b dark:bg-gray-400 z-[31]">
    //   <Link to="/" className="bg-[#F780B0]">
    //     구출이 어드민 goochoori admin
    //   </Link>
    //   {!isLoggedIn && (
    //     <div className="">
    //       <Link to="/login" className="text-white bg-gray-300">
    //         로그인
    //       </Link>
    //     </div>
    //   )}
    //   {isLoggedIn && (
    //     <div className="flex items-center gap-2">
    //       <div className="">
    //         <FontAwesomeIcon icon={faBell} />
    //       </div>

    //       <div className="text-sm">
    //         <button className="ont-semibold">{user.name}</button>
    //       </div>

    //       {/* dropdown */}
    //       {/* https://headlessui.dev/react/menu */}
    //       <Menu as="div" className="relative inline-block text-left z-[40]">
    //         <div className="">
    //           <Menu.Button>
    //             <img
    //               src={user.profileImage}
    //               alt={user.name}
    //               className="w-12 h-12 rounded-full"
    //             />
    //           </Menu.Button>
    //         </div>

    //         <Menu.Items className="fixed flex flex-col w-64 origin-top-right bg-white border divide-y divide-gray-300 top-16 right-4">
    //           <div className="flex items-center gap-2 p-4">
    //             <div className="">
    //               <img
    //                 src={user.profileImage}
    //                 alt={user.name}
    //                 className="w-10 h-10 rounded-full"
    //               />
    //             </div>
    //             <div className="flex flex-col flex-1">
    //               <div className="">{user.name}</div>
    //               <div className="flex gap-2">
    //                 <button className="text-blue-600">계정관리</button>
    //               </div>
    //             </div>
    //           </div>

    //           <div className="flex flex-col">
    //             <Menu.Item>
    //               {({ active }) => (
    //                 <div
    //                   className={`
    //                   ${active && 'bg-gray-200'}
    //                   px-4 py-2
    //                 `}>
    //                   <button className="">😄 {user?.name}</button>
    //                 </div>
    //               )}
    //             </Menu.Item>

    //             <Menu.Item>
    //               {({ active }) => (
    //                 <div
    //                   className={`
    //                   ${active && 'bg-gray-200'}
    //                   px-4 py-2
    //                 `}>
    //                   <button className="">{user?.id}</button>
    //                 </div>
    //               )}
    //             </Menu.Item>

    //             <Menu.Item>
    //               {({ active }) => (
    //                 <div
    //                   className={`
    //                   ${active && 'bg-gray-200'}
    //                   px-4 py-2
    //                 `}>
    //                   <button className="">
    //                     {user?.email ? user.email : 'no email'}
    //                   </button>
    //                 </div>
    //               )}
    //             </Menu.Item>
    //           </div>

    //           <div className="flex flex-col">
    //             <Menu.Item>
    //               {({ active }) => (
    //                 <button
    //                   className={`${
    //                     active && 'bg-gray-200'
    //                   } px-4 py-2 w-full flex`}>
    //                   <div className="flex-1 text-left">테마</div>
    //                   <div className="">{'>'}</div>
    //                 </button>
    //               )}
    //             </Menu.Item>

    //             <Menu.Item>
    //               {({ active }) => (
    //                 <button
    //                   className={`${
    //                     active && 'bg-gray-200'
    //                   } px-4 py-2 w-full flex`}
    //                   onClick={() => dispatch(logoutAsync())}>
    //                   로그아웃
    //                 </button>
    //               )}
    //             </Menu.Item>

    //             <div className="px-4 py-2">고객센터</div>
    //             <div className="px-4 py-2">설정</div>
    //             <div className="px-4 py-2">계정전환</div>
    //           </div>
    //         </Menu.Items>
    //       </Menu>
    //     </div>
    //   )}
    // </div>
  );
};

export default MenuBar;
