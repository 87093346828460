import axios from "../api/axios";

import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Notice } from "../type/notice";
import dayjs from "dayjs";

const NoticePage = () => {
  const [notices, setNotices] = useState<Notice[]>([]);
  const getNotice = useCallback(async () => {
    try {
      const res = await axios.get("/notices");
      console.log(res.data);
      setNotices(res.data.notices);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getNotice();
  }, [getNotice]);

  return (
    <div className="p-4">
      <div className="flex flex-row flex-1  items-center justify-between">
        <h1 className="text-2xl mb-3">공지사항/이벤트</h1>
        <Link
          to="/notice/add"
          className="bg-blue-400 py-2 px-4 text-white rounded-lg "
        >
          공지사항 추가
        </Link>
      </div>
      <ul className="mt-5">
        {notices.map((notice) => (
          <li className="py-4 px-4 flex flex-row justify-between hover:bg-green-300 border-t ">
            <Link className="w-full" to={`/notice/${notice.id}`}>
              [{notice.type === "notice" ? "공지사항" : "이벤트"}]{" "}
              {notice.title}
            </Link>
            <span className="text-sm text-gray-400">
              {dayjs(notice.createdAt).format("YYYY.MM.DD")}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NoticePage;
