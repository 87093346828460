import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { User } from "../type/user";
import Pagination from "./Pagination";
import PaginationFixed from "./PaginationFixed";
import {
  ECertificationStatus,
  EUserStatus,
  certificationStatusLabel,
  userStatusLabel,
} from "../lib/enum";

type TFilterCertType = ECertificationStatus | "all" | "leave";

function HeroTable() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectPage, setSelectPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filterCertificated, setFilterCertificated] =
    useState<TFilterCertType>("all");
  const [filterUserStatus, setFilterUserStatus] = useState<EUserStatus | "all">(
    "all"
  );
  const [total, setTotal] = useState(0);

  /** 페이지 진입 시 최초로 fetch하는 동작이 끝났는지 여부. 끝난 이후부턴 필터상태가 바뀔 때마다 fetch하기 위해 사용. */
  const refIsInitialFetchDone = useRef(false);

  const fetchSearchData = async ({ page }: { page?: number }) => {
    try {
      const res = await axios.get<{ heros: [User[], number] }>(
        `/admin/hero?page=${
          page ?? selectPage
        }&listNum=10&search=${search}&filterCert=${filterCertificated}&filterStatus=${filterUserStatus}`
      );

      setUsers(res.data.heros[0]);
      setTotal(res.data.heros[1]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSearchData({}).then(() => {
      refIsInitialFetchDone.current = true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("filterUserStatus: ", filterUserStatus);
    console.log("filterCertificated: ", filterCertificated);
    if (!refIsInitialFetchDone.current) return;

    setSelectPage(1);
    fetchSearchData({ page: 1 });
  }, [filterUserStatus, filterCertificated]);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <h1 className="text-2xl text-center">응급의료인 관리</h1>
        <div className="flex items-center">
          <select
            onChange={(e) => {
              setFilterCertificated(e.currentTarget.value as TFilterCertType);
            }}
          >
            <option value="all">승인 상태</option>
            <option value={ECertificationStatus.ACCEPTED}>승인완료</option>
            <option value={ECertificationStatus.VERIFYING}>승인대기</option>
            <option value="leave">탈퇴</option>
          </select>
          <select
            style={{ margin: "0 20px" }}
            onChange={(e) => {
              setFilterUserStatus(e.currentTarget.value as EUserStatus | "all");
            }}
          >
            <option value="all">이송 상태</option>
            <option value={EUserStatus.REST}>휴식</option>
            <option value={EUserStatus.READY}>대기</option>
            <option value={EUserStatus.WORKING}>이송중</option>
          </select>
          <div className="flex items-center">
            <input
              type="text"
              className="border px-2 py-1"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.code !== "Enter") return;

                setSelectPage(1);
                fetchSearchData({ page: 1 });
              }}
            />
            <div
              className="bg-gray-500 border px-2 py-1 text-white cursor-pointer"
              onClick={() => {
                setSelectPage(1);
                fetchSearchData({ page: 1 });
              }}
            >
              검색
            </div>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>프로필</th>
            <th>응급의료인</th>
            <th>ISP</th>
            <th>가입일</th>
            <th>자격검증</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: User) => (
            <tr
              key={user.id}
              onClick={(e) => navigate(`/users/hero/${user.id}`)}
            >
              <td>
                <img
                  className="w-12 h-12 mx-auto rounded-full"
                  src={user.profileImage}
                  alt={`${user.name}의 프로필 이미지`}
                />
              </td>
              <td>
                {user.name}({user.phoneNumber})
              </td>

              <td>{user.isp}</td>
              <td>{dayjs(user.createdAt).format("YYYY-MM-DD")}</td>
              <td
                className={`${
                  user.certification?.status === "verifying"
                    ? "text-[#26CB83]"
                    : user.certification?.status === "rejected"
                    ? "text-red-400"
                    : "text-gray-800"
                }`}
              >
                {certificationStatusLabel[user.certification?.status]}
              </td>
              <td
                className={`${
                  user.status === "ready"
                    ? "text-[#26CB83]"
                    : user.status === "rest"
                    ? "text-gray-800"
                    : "text-red-400"
                }`}
              >
                {user.certification?.status === ECertificationStatus.ACCEPTED
                  ? userStatusLabel[user.status] || "이송중"
                  : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {total !== 0 && (
        <PaginationFixed
          listNum={10}
          total={total}
          currentPage={selectPage}
          // setSelectPage={setSelectPage}
          onClickPage={(page) => {
            setSelectPage(page);
            fetchSearchData({ page });
          }}
        />
      )}
    </div>
  );
}

export default HeroTable;
