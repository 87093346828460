import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import { useAppSelector } from "../state/store";
import { selectUser } from "../state/userSlice";
import { Notice } from "../type/notice";

const NoticeDetail = () => {
  const [post, setPost] = useState<Notice>();
  const { postId } = useParams();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  const getPost = useCallback(async () => {
    try {
      const res = await axios.get<{ notice: Notice }>(`/notices/${postId}`);

      setPost(res.data.notice);
    } catch (error) {
      console.log(error);
    }
  }, [postId]);

  useEffect(() => {
    getPost();
  }, [getPost]);

  const deletePost = async () => {
    try {
      const result = window.confirm("정말로 삭제 하시나요?");
      if (!result) return;
      await axios.delete(`notices/${postId}`);
      navigate(`/notice`);
    } catch (error) {
      console.log(error);
    }
  };

  const editPost = () => {
    navigate(`/notice/edit/${post?.id}`);
  };

  return (
    <div className="noticeDatail">
      <div className=" flex flex-1 flex-row items-center justify-between p-2 border-b border-b-gray-400 mb-3">
        <div className="px-2">
          <h2 className="text-lg font-bold ">
            [{post?.type === "notice" ? "공지사항" : "이벤트"}] {post?.title}
          </h2>
          <p className="text-sm mt-1 text-gray-400">
            {dayjs(post?.createdAt).format("YYYY-MM-DD")}
          </p>
        </div>
        {user.role === "admin" && (
          <div>
            <button
              onClick={deletePost}
              className="py-1 px-5 mr-5 border border-sideBar-500 text-sideBar-500 rounded-md"
            >
              삭제
            </button>
            <button
              onClick={editPost}
              className="py-1 px-5 bg-sideBar text-white rounded-md "
            >
              수정
            </button>
          </div>
        )}
      </div>
      {post && (
        <div
          className="px-5"
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></div>
      )}
    </div>
  );
};

export default NoticeDetail;
