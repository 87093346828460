import React, { useEffect, useState } from 'react'
import axios from '../api/axios'

type Record = {
  id: number
  userRole: 'driver' | 'hero'
  latitude: string
  longitude: string
  platform: 'ios' | 'android'
  createdAt: string
  user: { id: number; name: string }
}
function LocationRecordsPage() {
  const [records, setRecords] = useState<Record[]>([])

  useEffect(() => {
    const fetchRecords = async () => {
      const res = await axios.get('/admin/locations/records')
      setRecords(res.data)
    }

    fetchRecords()
  }, [])
  return (
    <div className="p-4">
      <table>
        <thead>
          <tr className="text-left">
            <th className="px-2">id</th>
            <th className="px-2">userId</th>
            <th className="px-2">service</th>
            <th className="px-2">platform</th>
            <th className="px-2">latitude</th>
            <th className="px-2">longitude</th>
            <th className="px-2">recordedAt</th>
          </tr>
        </thead>
        <tbody>
          {records.map(r => (
            <tr>
              <td className="px-2">{r.id}</td>
              <td className="px-2">{r.user.id}</td>
              <td className="px-2">{r.userRole}</td>
              <td className="px-2">{r.platform}</td>
              <td className="px-2">{r.latitude}</td>
              <td className="px-2">{r.longitude}</td>
              <td className="px-2">{r.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LocationRecordsPage
