import dayjs from "dayjs";
import { OrderStatus } from "../type/Order";

export const formatDate = (date: string) => {
  return dayjs().diff(dayjs(date), "hour") < 1
    ? dayjs(date).fromNow()
    : dayjs().diff(dayjs(date), "hour") < 8
    ? dayjs(date).format("A h:mm")
    : dayjs(date).format("MM/DD HH:mm");
};

interface IOderStatus {
  [key: string]: string;
}
export const oderStatus: IOderStatus = {
  [OrderStatus.ARRIVED]: "완료됨",
  [OrderStatus.CANCELLED]: "취소됨",
  [OrderStatus.COMPLETED]: "완료됨",
  [OrderStatus.DEPARTED]: "이송중",
  [OrderStatus.DRIVER_MATCHED]: "매칭됨",
  [OrderStatus.FEE_CALCULATED]: "완료됨",
  [OrderStatus.FEE_REQUESTED]: "완료됨",
  [OrderStatus.HERO_MATCHED]: "이송중",
  [OrderStatus.HERO_PICKUPED]: "이송중",
  [OrderStatus.HERO_REQUESTED]: "이송중",
  [OrderStatus.HERO_REJECTED]: "구조사거절",
  [OrderStatus.LOADED]: "이송중",
  [OrderStatus.NO_DRIVER]: "취소됨",
  [OrderStatus.PENDING]: "매칭중",
  [OrderStatus.PICKUP_REJECTED]: "이송중",
  [OrderStatus.PICKUP_REQUESTED]: "이송중",
  [OrderStatus.STARTED]: "이송중",
};
